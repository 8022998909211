import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './App.scss';
import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css'
import Shell from './app/template/shell';
import Rotas from './app/core/routes';

function App() {
  AOS.init();
  
  return (
    <Shell>
      <Rotas />
    </Shell>
  );
}

export default App;
