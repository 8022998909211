import '../scss/linkDestaque.scss';
import '../../../../node_modules/@fortawesome/fontawesome-free/css/all.min.css'
import React from 'react';
import { Link } from 'react-router-dom';
import api from '../../config/api';

const LinkDestaque = (props) => {
    return (
        <div className="link-destaque">
            <img src={api + props.background} alt="imagem de fundo" />
            <div className="texto-primario">
                <h3>
                    {props.textoPrimario}
                </h3>
                <i className="fas fa-chevron-right"></i>
            </div>
            <div className="hover">
                <Link to={props.to} className="texto-secundario">
                    {props.textoBotao} <i className="fas fa-chevron-right"></i>
                </Link>
            </div>
        </div>
    );
}

export default LinkDestaque;