import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
import Slider from 'react-animated-slider';

import 'react-animated-slider/build/horizontal.css';
import './HomeStyle.css'
import api from '../../config/api';
import htmlParser from '../../utils/htmlParser';
import Loading from '../loading/Loading';
import { Link } from 'react-router-dom';

import ListaDestaque from '../widgets/ListaDestaque.jsx';
import LinkDestaque from '../../template/interface/LinkDestaque.jsx';

import Modal from './ModalComponent';

const fetcher = async url =>  await fetch(url).then(r => r.json())

const HomeComponent = (props) => {

    const [home, setHome] = useState({});
    const [waitScreen, setWaitScreen] = useState(true);
    const [activeModal, setActiveModal] = useState(false);
    const [goTo, setGoTo] = useState('');
    const fetched = useSWR(`${api}/home`, fetcher)
    fetched.loading = fetched.data === undefined;

    const showModal = (url) => {
        setActiveModal(true);
        setGoTo(url);
    }

    const updateActiveModal = (value) => {
        setActiveModal(value);
    };

    useEffect(() => {
        document.title = `ITCN - ${home.titulo ? home.titulo : ''}`;
        document.head.querySelector('meta[name=description]').content = home.resumo ? home.resumo : '';
    }, [home])

    useEffect(() => {
        if (fetched.loading) {
            setWaitScreen(true);
        } else {
            if (fetched.error) {
                setHome({ conteudo: "<h1>Ocorreu um erro, por favor, tente novamente</h1>" });
            } else {
                setHome({...fetched.data});
            }
            setWaitScreen(false);
        }
    }, [fetched.loading, fetched.error, fetched.data]);

    return (
        <React.Fragment>
            { waitScreen ? <Loading /> : 
                (
                    <React.Fragment>
                        {home.banner.length > 0 ?
                        <Slider disabled={home.banner.length === 1} duration="0" autoplay="10000">
                            {home.banner.map((slide, idx) => <div key={idx}>
                                <h2 data-aos="fade-down" data-aos-duration="1500">{slide.titulo}</h2>
                                <h3 
                                data-aos="fade-up" 
                                data-aos-duration="1200"
                                data-aos-delay="600">{slide.texto}
                                </h3>

                                <span className="actions-wrapper buttons" 
                                    data-aos="fade-right" 
                                    data-aos-duration="800"
                                    data-aos-delay="1000" >
                                    {slide.acoes.map((botao,idx) => 
                                        
                                        <span>
                                            { botao.acao === 'modal-suscription' ?  
                                                (
                                                    <Link key={idx} className="button is-success mx-2" to='#' onClick={ (e) => { showModal(botao.destino) } }>
                                                        {botao.texto}
                                                    </Link>
                                                ) :
                                                (
                                                    <Link key={idx} className="button is-success mx-2" to={botao.destino}>
                                                        {botao.texto}
                                                    </Link>
                                                )
                                            }
                                        </span>
                                        
                                    )}
                                </span>

                                <img src={`${api}${slide.imagem.url}`} alt="" />
                            </div>)}
                        </Slider>
                        : null }
                        {htmlParser(home.conteudo)}
                        <ListaDestaque limit={3} title="Últimas notícias"/>

                        <div class="container">
                        
                        {/* Suscription modal */}
                        <Modal 
                        isActive={ activeModal } 
                        updateActiveModal={ updateActiveModal }
                        goTo={goTo}
                        />
                        {/* Suscription modal */}

                        <br />
                        <br />
                            <div class="columns">
                                <div class="column">
                                    <LinkDestaque to="o-instituto" textoPrimario="Conheça o ITCN"  textoSecundario="O Instituto de Estudos Estratégicos de Tecnologia e Ciclo de Numerário (ITCN), é uma associação sem fins lucrativos que tem como objetivo principal …"  textoBotao="Saiba mais" background="/uploads/Conheca_o_ITCN_opcao_2_f4441dce88.jpeg" />
                                </div>

                                <div class="column">
                                    <LinkDestaque to="contato" textoPrimario="Fale conosco"  textoSecundario="Em que podemos ajudar?  clique no botão abaixo."  textoBotao="Fale conosco" background="/uploads/Opera_Instantanea_2022_04_06_153859_api_itcn_org_br_8945e41888.png" />
                                </div>
                            </div>
                        </div>

                    </React.Fragment>
                )
            }
        </React.Fragment>
    );
}

export default HomeComponent;