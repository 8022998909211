import React, { useState, useEffect } from 'react';
import htmlParser from '../../utils/htmlParser';

const Modal = (props) => {
    const { isActive = false, title = '', video = '', image = '', description = '' } = props;
    const [active, setActive] = useState(false);
    const [iframe, setIframe] = useState('');

    const closeModal = () => {
        
        let nav = document.getElementsByClassName("navbar")[0];
        let body = document.body;

        nav.style.zIndex = 30;
        body.style.overflow = 'initial';
        body.style.height = 'initial';

        setActive(false);
        setIframe('');
    }

    const showModal = () => {
        
        const html = video !== '' ? `<iframe
                        width="100%"
                        height="300px"
                        src="https://www.youtube.com/embed/${ video }"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                    />` : '';

                    setIframe(html);

        let nav = document.getElementsByClassName("navbar")[0];
        let body = document.body;

        nav.style.zIndex = 0;
        body.style.overflow = 'hidden';
        body.style.height = '100vh';

        setActive(true);
    }

    useEffect(() => {
        if(isActive){
            setActive(true);
        }
    }, [isActive]);
    

    return (
        <div>
            <div className="video-click" onClick={ showModal }>
                <figure>
                    <img src={ image !== '' ? image : '/assets/img/video.jpg' } alt = 'Link' />
                </figure>
            </div>
            <div className={`modal ${ active ? 'is-active' : ''}`}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                    <p className="modal-card-title">Vídeos</p>
                    <button className="delete" aria-label="close" data-bulma-modal="close" onClick={closeModal}></button>
                    </header>
                    <section className="modal-card-body">
                        { htmlParser(iframe) }
                        <br />
                        <br />
                        <h4>{ title }</h4>
                        <br />
                        { htmlParser( description ) }
                    </section>
                    <footer className="modal-card-foot">
                    <button className="button" data-bulma-modal="close" onClick={closeModal}>Fechar</button>
                    </footer>
                </div>
            </div>
        </div>
    );
}

export default Modal;