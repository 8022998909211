import React from 'react';
import htmlParser from '../../utils/htmlParser';
import { Link } from 'react-router-dom';
import api from '../../config/api';

const ListaItem = (props) => {
    const { post, columns, inClass } = props;
    return (
        <div className={`column is-${ columns !== undefined ? columns : '12' }`}>
            <div className={`article ${inClass !== undefined ? inClass : '' } `}>
                <div className="metadata">
                    <i className="far fa-clock"></i> { (new Date(post.data + ' 23:59:59')).toLocaleDateString() }
                    { post.autor ? htmlParser(` - Autor: ${ post.autor }`) : '' }
                </div>
                <div className="titulo">
                    <h2>
                        <Link to={`/documentos/${post.slug}`}>
                            { post.titulo }
                        </Link>
                    </h2>
                </div>
                { post.destaque ? 
                <div className="destaque">
                    <figure>
                        <Link to={`/documentos/${post.slug}`}>
                            <img src={`${api}${post.destaque.url}`} alt={post.destaque.alt} />
                        </Link>
                    </figure>
                </div> : null
                }
                <div className="conteudo">
                    { htmlParser(post.resumo ? post.resumo : post.conteudo) }
                </div>
            </div>
        </div>
    );
}

export default ListaItem;