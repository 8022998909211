import React, { useState, useEffect } from 'react';
import { useHomeAgenda } from '../../hooks/useAgenda';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');

const AgendaDestaque = () => {

    const [agenda, setAgenda] = useState([]);
    const {error, data, loading} = useHomeAgenda();

    useEffect(() => {
        if (!loading) {
            if (!error) {
                setAgenda([...data]);
            }
        }
    }, [loading, error, data]);

    return (
        <React.Fragment>
            {
            error ? <h3>Não temos agenda no momento</h3> 
            :
            <React.Fragment>
                {agenda.map(entry => {
                    return (
                        <article key={entry.id} className="agenda-destaque media">
                            <div className="media-content">
                                <div className="content">
                                    <Link to={`/agenda/${entry.slug}`}>{entry.titulo}</Link>
                                    <br />
                                    <time dateTime={entry.createdAt}>
                                        {moment(entry.createdAt).format('DD [de] MMMM [de] YYYY')}
                                    </time>
                                </div>
                            </div>
                        </article>
                    )
                    })
                }
            </React.Fragment>
            }
        </React.Fragment>
    );
}

export default AgendaDestaque;