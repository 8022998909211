import useSWR from 'swr';
import API from '../../app/config/api'

const fetcher = async url => await fetch(url).then(r => r.json())

const useFindAgenda = (page) => {
  const fetched = useSWR(`${API}/agendas?slug=${page}`, fetcher)
  fetched.loading = fetched.data === undefined;
  return fetched
}

const useHomeAgenda = () => {
  const fetched = useSWR(`${API}/agendas?_sort=createdAt%3Adesc&_limit=3`, fetcher)

  fetched.loading = fetched.data === undefined;
  return fetched
}

const useListarAgenda = () => {
  const fetched = useSWR(`${API}/agendas?_sort=createdAt%3Adesc`, fetcher)

  fetched.loading = fetched.data === undefined;
  return fetched
}

export {
  useFindAgenda,
  useListarAgenda,
  useHomeAgenda
};