import React, { useState, useEffect } from 'react';
import htmlParser from '../../utils/htmlParser';
import useSWR from 'swr';
import Loading from '../loading/Loading';
import ListaItem from './ListaItem';
import api from '../../config/api';

const videosMock = { slug: 'videos', titulo: 'Vídeos', resumo: 'Lista de vídeos do ITCN' };
const fetcher = async url =>  await fetch(url).then(r => r.json())

const Lista = (props) => {

    const {category} = props;
    const [lista, setLista] = useState([]);
    const [waitScreen, setWaitScreen] = useState(true);
    const listVideos = useSWR(`${api}/itcn-no-youtubes?_sort=createdAt%3Adesc&categorias_de_video=` + category, fetcher);

    useEffect(() => {
        document.title = `ITCN - ${videosMock.titulo}`;
        document.head.querySelector('meta[name=description]').content = videosMock.resumo;
    }, [lista])

    useEffect(() => {
        
        if (listVideos.loading) {
            setWaitScreen(true);
        } else {
            if (listVideos.error) {
                setLista([{ ...videosMock, conteudo: "<h1>Ocorreu um erro, por favor, tente novamente</h1>" }]);
            } else if (listVideos.data) {
                
                if (listVideos.data.length > 0){
                    setLista([...listVideos.data]);
                }else{
                    setLista([{ ...videosMock, conteudo: "<p>Não temos vídeos no momento</p>" }]);
                }
            }else{
                setLista([{ ...videosMock, conteudo: "<p>Não temos vídeos no momento</p>" }]);
            }
            setWaitScreen(false);
        }

    }, [listVideos.loading, listVideos.error, listVideos.data]);

    return (
        <div>
                { waitScreen ? (<Loading />) : 
                <div className="container">
                        <section id="conteudo" className="columns is-multiline">
                            { props.query.has('q') && props.query.get('q') !== '' ?
                            <div id="resultados" className="is-size-3 mb-5">Resultados da pesquisa: {encodeURIComponent(props.query.get('q'))}</div>
                            : null }
                            { lista && lista.length > 0 ?
                                lista.map(entry => {
                                    if (!entry.hasOwnProperty('_id')) {
                                        return htmlParser(entry.conteudo);
                                    }
                                    return <ListaItem key={entry.id} post={entry} inClass="" columns="3" />;
                                })
                            : null }
                        </section>
                        {/* <Sidebar /> */}
                </div>
                }
        </div>
    );
}

export default Lista;