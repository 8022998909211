import '../scss/footer.scss';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
// import Modal from './ModalComponent';

const Footer = (props) => {
    return (
        <footer>
            <div className={'container'}>
                <div className={'columns is-multiline'}>
                    <div className={'column is-full'}>
                        <span className={'icon'}><FontAwesomeIcon icon={faEnvelope} /></span> diretoria@itcn.org.br
                    </div>
                    <div className={'column is-full is-half-desktop'}>
                        <p>
                            O Instituto de Estudos Estratégicos de Tecnologia e Ciclo de Numerário (ITCN), 
                            é uma associação sem fins lucrativos que tem como objetivo principal o 
                            desenvolvimento de estudos relacionados a inovações tecnológicas na área de 
                            gestão de <a href="https://www.itcn.org.br/documentos/numerario-e-ecossistema-de-pagamentos" className='text-green'>Ciclo de Numerário.</a>
                        </p>
                        <div>
                        <span>
                            <span className="icon">
                                <i className="fab fa-linkedin-in"></i>
                            </span>
                            <a href="https://www.linkedin.com/company/itcnorgbr/" target="_blank" rel="noopener noreferrer">Linkedin</a>
                        </span> 
                        <span className="ml-6">
                            <span className="icon">
                                <i className="fab fa-instagram"></i>
                            </span>
                            <a href="https://www.instagram.com/itcn.online/" target="_blank" rel="noopener noreferrer">Instagram</a>
                        </span>
                        </div>
                    </div>
                    <div className={'column is-full is-half-desktop'}>
                        <div className={'columns is-multiline is-mobile'}>
                            <div className={'column is-half'}>
                                <Link to="/"><FontAwesomeIcon icon={faCaretRight} /> Home</Link>
                            </div>
                            <div className={'column is-half'}>
                                <Link to="/o-instituto"><FontAwesomeIcon icon={faCaretRight} /> O Instituto</Link>
                            </div>
                            <div className={'column is-half'}>
                                <Link to="/noticias"><FontAwesomeIcon icon={faCaretRight} /> ITCN na Mídia</Link>
                            </div>
                            <div className={'column is-half'}>
                                <Link to="/documentos"><FontAwesomeIcon icon={faCaretRight} /> Documentos e Publicações</Link>
                            </div>
                            <div className={'column is-half'}>
                                <Link to="/agenda"><FontAwesomeIcon icon={faCaretRight} /> Atividades/Agenda</Link>
                            </div>
                            <div className={'column is-half'}>
                                {/*<Link to="/associe-se"><FontAwesomeIcon icon={faCaretRight} /> Associe-se</Link>*/}
                            </div>
                            <div className={'column is-half'}>
                                <Link to="/contato"><FontAwesomeIcon icon={faCaretRight} /> Contato</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'container'}>
                <div id={"developedBy"} className={"columns"}>
                    <div className={'column is-half'}>
                        Copyright {(new Date()).getFullYear()} ITCN
                    </div>
                    <div className={'column is-half has-text-right'}>
                        <a href="https://agenciacarcara.com.br/" target="_blank" rel="noopener noreferrer">Desenvolvido por Agência Carcará</a>
                    </div>
                </div>
            </div>
            { /* <Modal isActive={ true } title={ '' } content={'<p><img src="/assets/img/pop-up-index.png"/></p>'} url="/" classIn="modal-lg" /> */ }
        </footer>
    );
};

export default Footer;