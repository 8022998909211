import React, { useState, useEffect } from 'react';
import { useHomeNoticias } from '../../hooks/useNoticias';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';
import htmlParser from '../../utils/htmlParser';
import api from '../../config/api';

moment.locale('pt-br');

const NoticiasDestaque = () => {

    const [noticias, setNoticias] = useState([]);
    const history = useHistory();
    const {error, data, loading} = useHomeNoticias();

    useEffect(() => {
        if (!loading) {
            if (!error) {
                setNoticias([...data]);
            }
        }
    }, [loading, error, data]);

    const handleCardClick = (entry) => {
        history.push(`/noticias/${entry.slug}`);
    }

    return (
        <div id="noticias-destaque" className="columns is-multiline mb-6">
            <div className="column is-full">
                <h2 className='destaque has-text-left mb-6'>
                    Notícias
                </h2>
            </div>
            {
                error ? <h3>Não temos notícias no momento</h3> 
                :
                <React.Fragment>
                { noticias.length === 0
                    ? <div className="column is-full">Nâo temos notícias no momento</div>
                    :
                    <React.Fragment>
                        <div className="column is-8">
                            <div className="columns">
                                {noticias.slice(0, 2).map(entry => {
                                    return (
                                        <div key={entry.id} className="column is-half">
                                            <div onClick={() => handleCardClick(entry)} className="card">
                                                <div className="card-image">
                                                    <figure className="image is-4by3">
                                                        <img src={`${api}${entry.destaque.url}`} alt={entry.destaque.alternativeText} />
                                                    </figure>
                                                </div>
                                                <div className="card-content">
                                                    <div className="content">
                                                    <p>
                                                        <Link to={`/noticias/${entry.slug}`}>{entry.titulo}</Link>
                                                    </p>
                                                        {entry.resumo ? entry.resumo : htmlParser(String.prototype.substr.apply(entry.conteudo, [0, 100]) + '...')}
                                                        <br />
                                                        <time dateTime={entry.createdAt}>
                                                            <span>{moment(entry.createdAt).format('DD')}</span>
                                                            {moment(entry.createdAt).format('MMM')}
                                                        </time>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="column is-4">
                            {noticias.slice(2, 4).map(entry => {
                                return (
                                    <article key={entry.id} className="media">
                                        <figure className="media-left">
                                            <p className="image is-96x96">
                                                <img src={`${api}${entry.destaque.url}`} alt={entry.destaque.alternativeText} />
                                            </p>
                                        </figure>
                                        <div className="media-content">
                                            <div className="content">
                                                <Link to={`/noticias/${entry.slug}`}>{entry.titulo}</Link>
                                                <br />
                                                <time dateTime={entry.createdAt}>
                                                    {moment(entry.createdAt).format('DD [de] MMMM [de] YYYY')}
                                                </time>
                                            </div>
                                        </div>
                                    </article>
                                )
                                })
                            }
                            {noticias.slice(4, 6).map(entry => {
                                return (
                                    <article key={entry.id} className="media">
                                        <div className="media-content">
                                            <div className="content">
                                                <Link to={`/noticias/${entry.slug}`}>{entry.titulo}</Link>
                                                <br />
                                                <time dateTime={entry.createdAt}>
                                                    {moment(entry.createdAt).format('DD [de] MMMM [de] YYYY')}
                                                </time>
                                            </div>
                                        </div>
                                    </article>
                                )
                                })
                            }
                        </div>
                    </React.Fragment>
                }
                </React.Fragment>
            }
        </div>
    );
}

export default NoticiasDestaque;