import React, { useState, useEffect } from 'react';
import htmlParser from '../../utils/htmlParser';
import useSWR from 'swr';
import Loading from '../loading/Loading';
import ListaItem from './ListaItem';
import api from '../../config/api';
// import Sidebar from '../../template/interface/Sidebar';

const documentosMock = { slug: 'documentos e publicações', titulo: 'Documentos e publicações', resumo: 'Lista de documentos e publicações do ITCN' };
const fetcher = async url =>  await fetch(url).then(r => r.json())

const Lista = (props) => {

    const {category} = props;
    const [lista, setLista] = useState([]);
    const [waitScreen, setWaitScreen] = useState(true);
    const listDocument = useSWR(`${api}/documentos-publicacoes?_sort=createdAt%3Adesc&categorias_documentos_publicacoe=` + category, fetcher);

    useEffect(() => {
        document.title = `ITCN - ${documentosMock.titulo}`;
        document.head.querySelector('meta[name=description]').content = documentosMock.resumo;
    }, [lista])

    useEffect(() => {
        
        if (listDocument.loading) {
            setWaitScreen(true);
        } else {
            if (listDocument.error) {
                setLista([{ ...documentosMock, conteudo: "<h1>Ocorreu um erro, por favor, tente novamente</h1>" }]);
            } else if (listDocument.data) {
                
                if (listDocument.data.length > 0){
                    setLista([...listDocument.data]);
                }else{
                    setLista([{ ...documentosMock, conteudo: "<p>Não temos documentos e publicações no momento</p>" }]);
                }
            }else{
                setLista([{ ...documentosMock, conteudo: "<p>Não temos documentos e publicações no momento</p>" }]);
            }
            setWaitScreen(false);
        }

    }, [listDocument.loading, listDocument.error, listDocument.data]);

    return (
        <div>
            <main className="documentos page">
                { waitScreen ? (<Loading />) : 
                <div className="container">
                        <section id="conteudo" className="columns is-multiline">
                            { props.query.has('q') && props.query.get('q') !== '' ?
                            <div id="resultados" className="is-size-3 mb-5">Resultados da pesquisa: {encodeURIComponent(props.query.get('q'))}</div>
                            : null }
                            { lista && lista.length > 0 ?
                                lista.map(entry => {
                                    if (!entry.hasOwnProperty('_id')) {
                                        return htmlParser(entry.conteudo);
                                    }
                                    return <ListaItem key={entry.id} post={entry} inClass="list-article" columns="6" />;
                                })
                            : null }
                        </section>
                        {/* <Sidebar /> */}
                </div>
                }
            </main>
        </div>
    );
}

export default Lista;