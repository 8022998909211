import React, { useState, useEffect } from 'react';
import usePage from '../../hooks/usePage';
import { useParams } from 'react-router-dom';
import htmlParser from '../../utils/htmlParser';


const PaginaComponent = (props) => {

    const [page, setPage] = useState({});
    const { slug } = useParams();
    const {error, data, loading} = usePage(slug);

    useEffect(() => {
        document.title = `ITCN - ${page.titulo}`;
        document.head.querySelector('meta[name=description]').content = page.descricao;
    }, [page])

    useEffect(() => {
        if (error) {
            setPage({ conteudo: "<h1>Ocorreu um erro, por favor, tente novamente</h1>" });
        } else if (loading) {
            setPage({ conteudo: '<Loading />' });
        } else if (data.length === 0) {
            setPage({ conteudo: "<h1>Página não encontrada</h1>" });
        } else {
            setPage(data[0]);
        }
    }, [error, data, loading]);

    return (
        <main id="page" className={page.slug}>
            <div id="conteudo" className="container page">
                { htmlParser(page.conteudo) }
            </div>
        </main>
    );
}

export default PaginaComponent;