import React, { useRef } from "react";
import InputMask from 'react-input-mask';
import { useState } from "react";
import api from "../../config/api";

const mockPessoa = {
    nome: '',
    rg: '',
    cpf: '',
    dataNascimento: '',
    telefoneComercial: '',
    enderecoComercial: '',
    endereco: {
        cep: '',
        rua: '',
        bairro: '',
        cidade: '',
        estado: ''
    },
    telefone: '',
    email: '',
    profissao: '',
    atuacao: '',
    conhecimento: '',
    declaracao: '',
    documento: null
};

const FormPessoaFisica = () => {
    const [pessoa, setPessoa] = useState(mockPessoa)
    const [errors, setErrors] = useState({})
    const [msgRetorno, setMsgRetorno] = useState(null)

    const cpfRef = useRef(null);
    const telefoneComercial = useRef(null);
    const telefone = useRef(null);
    const cep = useRef(null);

    const enviarPessoaFisica = (event) => {
        event.preventDefault();
        setErrors({});
        setMsgRetorno(null);
        
        let newErrors = {};
        
        if (pessoa.nome === '') {
            newErrors.nome = true;
        }

        if (pessoa.rg === '') {
            newErrors.rg = true;
        }

        if (pessoa.cpf === '') {
            newErrors.cpf = true;
        }

        if (pessoa.dataNascimento === '') {
            newErrors.dataNascimento = true;
        }

        if (pessoa.telefoneComercial === '') {
            newErrors.telefoneComercial = true;
        }

        if (pessoa.telefone === '') {
            newErrors.telefone = true;
        }

        if (pessoa.email === '') {
            newErrors.email = true;
        }

        if (!pessoa.documento || pessoa.documento === '') {
            newErrors.documento = true;
        }

        if (Object.values(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        
        const formData = new FormData();
        let data = {...pessoa};
        formData.append('files.documento',data.documento, data.documento.name);
        delete data.documento;

        formData.append('data', JSON.stringify(data));
        fetch(api + '/pessoa-fisicas', {
            method: 'POST',
            body: formData
        })
        .then(resp => resp.json)
        .then(data => {
            setPessoa(mockPessoa);
            setMsgRetorno("Dados enviados com sucesso");
        })
        .catch(error => {
            setMsgRetorno("Ocorreu um erro, por favor tente mais tarde");
        });
        return false;
    }

    const handleChange = (event) => {
        let newPessoa = {...pessoa};
        newPessoa[event.target.name] = event.target.value;
        setPessoa(newPessoa);
    }

    const handleChangeEndereco = (event) => {
        let newPessoa = {...pessoa};
        newPessoa.endereco[event.target.name] = event.target.value;
        setPessoa(newPessoa);
    }

    const handleChangeFile = (event) => {
        let newPessoa = {...pessoa};
        newPessoa.documento = event.target.files[0];
        setPessoa(newPessoa);
    }

    return (
        <form onSubmit={enviarPessoaFisica}>
            <div className="field">
                <div className="control">
                    <input name="nome" value={pessoa.nome} onChange={handleChange} className={errors.nome ? 'input is-danger' : 'input'} type="text" placeholder="Nome completo" />
                </div>
                {errors.nome ? <p className="help is-danger">Campo obrigatório</p> : ''}
            </div>

            <div className="field">
                <div className="control">
                    <input name="rg" value={pessoa.rg}  onChange={handleChange} className={errors.rg ? 'input is-danger' : 'input'} type="number" placeholder="RG" />
                </div>
                {errors.rg ? <p className="help is-danger">Campo obrigatório</p> : ''}
            </div>

            <div className="field">
                <div className="control">
                    <InputMask mask={"999.999.999-99"} className={errors.cpf ? 'input is-danger' : 'input'} name="cpf" placeholder="CPF"  value={pessoa.cpf}  onChange={handleChange} inputRef={cpfRef} />
                </div>
                {errors.cpf ? <p className="help is-danger">Campo obrigatório</p> : ''}
            </div>

            <div className="field">
                <label className="label">Data de nascimento</label>
                <div className="control">
                    <input className={errors.dataNascimento ? 'input is-danger' : 'input'} name="dataNascimento" placeholder="Data de nascimento" type="date"  value={pessoa.dataNascimento}  onChange={handleChange} />
                </div>
                {errors.dataNascimento ? <p className="help is-danger">Campo obrigatório</p> : ''}
            </div>

            <div className="field">
                <div className="control">
                    <InputMask mask={"(99) 9999-9999"} className={errors.telefoneComercial ? 'input is-danger' : 'input'} name="telefoneComercial" placeholder="Telefone Comercial"  value={pessoa.telefoneComercial}  onChange={handleChange} inputRef={telefoneComercial} />
                </div>
                {errors.telefoneComercial ? <p className="help is-danger">Campo obrigatório</p> : ''}
            </div>

            <div className="field">
                <div className="control">
                    <input name="enderecoComercial" value={pessoa.enderecoComercial} onChange={handleChange} className='input' type="text" placeholder="Endereço Comercial" />
                </div>
            </div>

            <div className="is-size-6">Endereço residencial</div>

            <div className="field">
                <div className="control">
                    <InputMask mask={"99999-999"} className='input' name="cep" placeholder="CEP"  value={pessoa.endereco.cep}  onChange={handleChangeEndereco} inputRef={cep} />
                </div>
            </div>

            <div className="field">
                <div className="control">
                    <input name="rua" value={pessoa.endereco.rua} onChange={handleChangeEndereco} className='input' type="text" placeholder="Rua" />
                </div>
            </div>

            <div className="field">
                <div className="control">
                    <input name="bairro" value={pessoa.endereco.bairro} onChange={handleChangeEndereco} className='input' type="text" placeholder="Bairro" />
                </div>
            </div>

            <div className="field">
                <div className="control">
                    <input name="cidade" value={pessoa.endereco.cidade} onChange={handleChangeEndereco} className='input' type="text" placeholder="Cidade" />
                </div>
            </div>

            <div className="field">
                <div className="control">
                    <input name="estado" value={pessoa.endereco.estado} onChange={handleChangeEndereco} className='input' type="text" placeholder="Estado" />
                </div>
            </div>

            <div className="field">
                <div className="control">
                    <InputMask mask={"(99) 9999-9999"} className={errors.telefone ? 'input is-danger' : 'input'} name="telefone" placeholder="Telefone"  value={pessoa.telefone}  onChange={handleChange} inputRef={telefone} />
                </div>
                {errors.telefone ? <p className="help is-danger">Campo obrigatório</p> : ''}
            </div>

            <div className="field">
                <div className="control">
                    <input name="email" value={pessoa.email} onChange={handleChange} className={errors.email ? 'input is-danger' : 'input'} type="text" placeholder="E-mail" />
                </div>
                {errors.email ? <p className="help is-danger">Campo obrigatório</p> : ''}
            </div>

            <div className="field">
                <div className="control">
                    <input name="profissao" value={pessoa.profissao} onChange={handleChange} className='input' type="text" placeholder="Profissao" />
                </div>
            </div>

            <div className="field">
                <div className="control">
                    <input name="atuacao" value={pessoa.atuacao} onChange={handleChange} className='input' type="text" placeholder="Área de Atuação" />
                </div>
            </div>

            <div className="field">
                <strong>COMO OUVIU FALAR DO ITCN:</strong>
                <div className="control">
                    <label className="radio">
                        <input type="radio" checked={!pessoa.conhecimento || pessoa.conhecimento === 'indicacao' ? true : false} onChange={handleChange} name="conhecimento" value="indicacao" />
                        &nbsp;INDICAÇÃO
                    </label>
                    <label className="radio">
                        <input type="radio" checked={pessoa.conhecimento === 'pesquisa' ? true : false} onChange={handleChange} name="conhecimento" value="pesquisa" />
                        &nbsp;PESQUISA NA WEB
                    </label>
                    <label className="radio">
                        <input type="radio" checked={pessoa.conhecimento === 'evento' ? true : false} onChange={handleChange} name="conhecimento" value="evento" />
                        &nbsp;EVENTO
                    </label>
                    <label className="radio">
                        <input type="radio" checked={pessoa.conhecimento === 'midia' ? true : false} onChange={handleChange} name="conhecimento" value="midia" />
                        &nbsp;MÍDIA
                    </label>
                    <label className="radio">
                        <input type="radio" checked={pessoa.conhecimento === 'outros' ? true : false} onChange={handleChange} name="conhecimento" value="outros" />
                        &nbsp;OUTROS
                    </label>
                </div>
            </div>

            <div className="file  has-name is-fullwidth">
                <label className="file-label">
                    <input 
                        className="file-input"
                        accept="image/jpg, image/jpeg, application/pdf, application/doc, application/docx"
                        onChange={handleChangeFile} type="file" name="documento" />
                    <span className="file-cta">
                        <span className="file-icon">
                            <i className="fas fa-upload"></i>
                        </span>
                        <span className="file-label">
                            Anexar: Cópia de documento oficial com foto nos formatos pdf, doc, jpg
                        </span>
                    </span>
                    <span className="file-name">
                        { pessoa.documento && pessoa.documento.name ? pessoa.documento.name : '' }
                    </span>
                </label>
                {errors.documento ? <p className="help is-danger">Campo obrigatório</p> : ''}
            </div>


            <div className="field">
                <p>&nbsp;</p>
                <strong>Declaro, que todas as informações acima prestadas são verdadeiras. Declaro ainda ter ciência e concordância com todas as previsões estatutárias do ITCN, comprometendo-me a zelar pelos valores e princípios da instituição, acatar as deliberações da diretoria bem como cumprir todas as normas e regras dispostas no seu estatuto.</strong>
                <div className="control">
                    <label className="radio">
                        <input type="radio" onChange={handleChange} name="declaracao" value="sim" />
                        &nbsp;SIM
                    </label>
                    <label className="radio">
                        <input type="radio" checked={true} onChange={handleChange} name="declaracao" value="nao" />
                        &nbsp;NÃO
                    </label>
                </div>
            </div>

            <div className="field is-grouped">
                <div className="control">
                    <button type="submit" className="button is-success">Enviar Solicitação</button>
                </div>
            </div>

            { msgRetorno ? <article class="message is-info">
                <div class="message-body">
                    { msgRetorno }
                </div>
            </article> : null }
        </form>
    );
};

export default FormPessoaFisica;