import React, { useState, useEffect } from 'react';
import { useFindDocumento } from '../../hooks/useDocumentos';
import Loading from '../loading/Loading';
import ListaItem from './ListaItem';
import Sidebar from '../../template/interface/Sidebar';

const noticiasMock = { slug: 'noticias', titulo: 'Notícias', resumo: 'Lista de noticias do ITCN' };

const Single = (props) => {

    const [entry, setEntry] = useState([]);
    const [waitScreen, setWaitScreen] = useState(true);
    const {error, data, loading} = useFindDocumento(props.slug);

    useEffect(() => {
        document.title = `ITCN - ${entry.titulo}`;
        document.head.querySelector('meta[name=description]').content = entry.resumo;
    }, [entry])

    useEffect(() => {
        if (loading) {
            setWaitScreen(true);
        } else {
            if (error) {
                setEntry([{ ...noticiasMock, conteudo: "<h1>Ocorreu um erro, por favor, tente novamente</h1>" }]);
            } else if (data.length === 0) {
                setEntry([{ ...noticiasMock, conteudo: "<p>Não temos notícias no momento</p>" }]);
            } else {
                setEntry(data[0]);
            }
            setWaitScreen(false);
        }
    }, [error, data, loading]);

    return (
        <main id="page" className="noticias">
            { waitScreen ? (<Loading />) : 
            <div className="container">
                <div className="columns is-5 is-variable">
                    <section id="conteudo" className="column is-8">
                        <ListaItem key={entry._id} post={entry} />
                    </section>
                    <Sidebar />
                </div>
            </div>
            }
        </main>
    );
}

export default Single;