import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import './DocumentoStyle.css';
import useSWR from 'swr';
import Lista from './Lista';
import Single from './Single';
import api from '../../config/api';

const fetcher = async url =>  await fetch(url).then(r => r.json())

const DocumentoComponent = () => {

    const listCategories = useSWR(`${api}/categorias-documentos-publicacoes?_sort=createdAt%3Aasc`, fetcher);

    const [category, setCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const { slug } = useParams();
    const query = new URLSearchParams(useLocation().search);

    const changeTab = (item) => {

        categories.forEach((val, index) => categories[index].active = '');
        categories[item].active = 'is-active';

        setCategory(categories[item].id);
        setCategories([...categories]);
    }

    useEffect(() => {
        if (listCategories.loading) {
            
        } else {
            if (listCategories.error) {
               
            } else {
                if(listCategories.data){
                    let data = [];

                    listCategories.data.forEach((val, index) => {
                        data.push({ titulo: val.titulo, id: val.id ,active: index === 0 ? 'is-active' : '' });

                        if(index === 0){
                            setCategory(val.id);
                        }
                    });
                    
                    setCategories([...data]);
                }
            }
        }
    }, [listCategories.loading, listCategories.error, listCategories.data]);

    return (
        <React.Fragment>
            { slug ? (<Single slug={slug} />) : (
                <div>
                    <div className="banner-notice">
                        <h2 className="page-title">Documentos e publicações</h2>
                    </div>
                    <main id="page" className="noticias">
                        <div className="container">
                            <div className="column  is-full">
                                <div className="tabs is-centered is-boxed">
                                    <ul>
                                        { categories && categories.length > 0 ?
                                            categories.map((category, index) => {
                                                return <li className={ category.active } onClick={ () => {
                                                    changeTab(index)
                                                } }><a href="#!">{ category.titulo }</a></li>
                                            })
                                        : null }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <Lista query={query} category={category}/> 
                    </main>
                </div>
                
            ) }
        </React.Fragment>
    );
}

export default DocumentoComponent;