import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../../config/api';

const Modal = (props) => {
    const { isActive = false, goTo } = props;
    const [active, setActive] = useState(false);
    const [email, setEmail] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [sendData, setSendData] = useState(false);

    const closeModal = () => {
        
        let nav = document.getElementsByClassName("navbar")[0];
        let body = document.body;

        nav.style.zIndex = 30;
        body.style.overflow = 'initial';
        body.style.height = 'initial';

        setActive(false);
        updateActiveModalParents(false);
    }

    const updateActiveModalParents = () =>{
        props.updateActiveModal(false)
    }

    const handleEmailChange = (e) => {
        const inputEmail = e.target.value;
        setEmail(inputEmail);

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = emailRegex.test(inputEmail);
        setIsValid(isValidEmail);
    }

    const handleCrearRegistro = async () => {

        if(!isValid){
            return false;
        }

        try {
            setLoading(true);

            const fechaActual = new Date().toISOString();
    
            const datos = {
                email: email,
                data: fechaActual,
            };
    
            const respuesta = await fetch(`${api}/inscricaos`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify(datos),
            });
    
            if (respuesta.ok) {
                setMessage('Registro creado exitosamente.');
                console.log(message);
            } else {
                setMessage(`Error al crear el registro: ${respuesta.statusText}`);
                console.log(message);
            }

            setSendData(true);

        } catch (error) {
            setMessage(`Error en la solicitud`);
            console.log(message);
        }finally {
            setLoading(false);
        }
      };

    const showModal = () => {

        let nav = document.getElementsByClassName("navbar")[0];
        let body = document.body;

        nav.style.zIndex = 0;
        body.style.overflow = 'hidden';
        body.style.height = '100vh';

        setActive(true);
    }

    useEffect(() => {
        if(isActive){
            setActive(true);
            showModal();
        }
    }, [isActive]);
    

    return (
        <div>
            <div className={`modal ${ active ? 'is-active' : ''}`}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title my-0">Download do arquivo</p>
                        <button className="delete" aria-label="close" data-bulma-modal="close" onClick={closeModal}></button>
                    </header>
                    <section className="modal-card-body">

                        { sendData ? (
                            <div className='has-text-centered'>
                                <h5 className='mb-5'>Clique no botão para download do arquivo.</h5>
                                <Link className="button is-success has-text-white mx-2" target="_blank" to={ goTo }>
                                    Download do arquivo <i class="fas fa-download ml-3"></i>
                                </Link>
                            </div>
                        ):
                        (   
                            ''
                        ) }

                        { !sendData && !loading ? (
                            <div>
                                <h5 className='mb-5'>Por favor insira o seu e-mail para realizar o download do arquivo.</h5>
                                <div className="field">
                                    <label className="label">E-mail:</label>
                                    <div className="control">
                                        <input type="text" value={email} onChange={(e) => handleEmailChange(e)} className="input"/>
                                    </div>
                                </div>
                            </div>
                        ):(
                            ''
                        ) }

                        {loading && <p className='has-text-centered'>Os dados estão sendo enviados, aguarde...</p>}
                        {!isValid && email !== '' ? (
                            <p>Por favor digite um email válido</p>
                        ):(
                            ''
                        )} 

                    </section>
                    <footer className="modal-card-foot">
                        <button className="button" data-bulma-modal="close" onClick={closeModal}>Fechar</button>
                        
                        { isValid && !sendData ? (
                            <button className='button is-success' onClick={handleCrearRegistro}>Enviar</button>
                        ):(
                            ''
                        ) }

                    </footer>
                </div>
            </div>
        </div>
    );
}

export default Modal;