import '../scss/header.scss';
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import useWindowSize from '../../hooks/useWindowSize';
import useWindowScroll from '../../hooks/useWindowScroll';
import { Link } from 'react-router-dom';

const Header = (props) => {

    const [menuOpen, setMenuOpen] = useState(false);
    const [menuFixed, setMenuFixed] = useState(false);
    const [winWidth] = useWindowSize();
    const [posX, posY] = useWindowScroll();

    useEffect(() => {
        setMenuFixed(false)
        if (winWidth > 1024) {
            setMenuOpen(false);
        }

        if (posY > 100) {
            setMenuFixed(true)
        }
    },[winWidth, posY, posX])

    const handleToggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    return (
        <header className={ !menuFixed ? "" : "has-menu-fixed" } >
            <div id="topbar" className={'has-text-centered'}>
                <div className={'container'}>
                    <div className={'columns is-vcentered'}>
                        <div className={'column is-half'}>
                            <FontAwesomeIcon icon={faEnvelope} /> Email: diretoria@itcn.org.br
                        </div>
                        <div className={'column is-half'}>
                            <span><FontAwesomeIcon icon={faQuestionCircle} /> Quer saber mais?</span>
                            {/*<Link to="/associe-se">Associe-se</Link>*/}
                        </div>
                    </div>
                </div>
            </div>
            <div className={'container'}>
                <nav className={ !menuFixed ? "navbar" : "navbar fixed" } role="navigation" aria-label="main navigation">
                    <div className="navbar-brand">
                        <Link to="/" className="navbar-item">                            
                            <img src="/assets/img/logo-itcn.svg" alt="Logo ITCN - Instituto de Estudos Estratégicos de Tecnologia e Ciclo Numerário" />
                        </Link>

                        <button 
                            onClick={handleToggleMenu} 
                            className={menuOpen ? "navbar-burger button is-active  is-text" : "navbar-burger button is-text" } 
                            aria-label="menu" 
                            aria-expanded="false" 
                            data-target="menu-top">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        </button>
                    </div>

                    <div id="menu-top" className={menuOpen ? "navbar-menu is-active" : "navbar-menu" } >
                        <div className="navbar-end">
                            <div className="navbar-item">
                                <Link to="/o-instituto">O Instituto</Link>
                            </div>
                            <div className="navbar-item">
                                <Link to="/noticias">ITCN na Mídia</Link>
                            </div>
                            <div className="navbar-item">
                                <Link to="/documentos">Documentos e Publicações</Link>
                            </div>
                            <div className="navbar-item">
                                <Link to="/agenda">Atividades/Agenda</Link>
                            </div>
                            <div className="navbar-item">
                                <Link to="/videos">ITCN no Youtube</Link>
                            </div>
                            {/*<div className="navbar-item">
                                <Link to="/associe-se">Associe-se</Link>
                            </div>*/}
                            <div className="navbar-item">
                                <Link to="/contato">Contato</Link>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default Header;