import React, { useRef } from "react";
import InputMask from 'react-input-mask';
import { useState } from "react";
import api from "../../config/api";

const mockPessoa = {
    nomeFantasia: '',
    razaoSocial: '',
    site: '',
    cnpj: '',
    inscricaoEstadual: '',
    inscricaoMunicipal: '',
    cep: '',
    endereco: '',
    telefone: '',
    email: '',
    cnae: '',
    atuacao: '',
    dataPagamento: '',
    representante: '',
    representanteEmail: '',
    representanteTelefone: '',
    tipo: '',
    porte: '',
    planoPagamento: '',
    atividades: '',
    indicadoNome: '',
    indicadoRG: '',
    indicadoCPF: '',
    indicadoEndereco: '',
    indicadoEmail: '',
    indicadoTelefone: '',
    conhecimento: '',
    declaracao: '',
    documento: null
};

const FormPessoaJuridica = () => {
    const [pessoa, setPessoa] = useState(mockPessoa)
    const [errors, setErrors] = useState({})
    const [msgRetorno, setMsgRetorno] = useState(null)

    const cpfRef = useRef(null);
    const telefoneComercial = useRef(null);
    const telefone = useRef(null);
    const cep = useRef(null);

    const enviarPessoaFisica = (event) => {
        event.preventDefault();
        setErrors({});
        setMsgRetorno(null);
        
        let newErrors = {};
        
        for (const key in pessoa) {
            if (pessoa.hasOwnProperty(key) && (pessoa[key] === '' || pessoa[key] === null)) {
                newErrors[key] = true;
            }
        }

        if (Object.values(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        
        const formData = new FormData();
        let data = {...pessoa};
        formData.append('files.documento',data.documento, data.documento.name);
        delete data.documento;

        formData.append('data', JSON.stringify(data));
        fetch(api + '/pessoa-juridicas', {
            method: 'POST',
            body: formData
        })
        .then(resp => resp.json)
        .then(data => {
            setPessoa(mockPessoa);
            setMsgRetorno("Dados enviados com sucesso");
        })
        .catch(error => {
            setMsgRetorno("Ocorreu um erro, por favor tente mais tarde");
        });
        return false;
    }

    const handleChange = (event) => {
        let newPessoa = {...pessoa};
        newPessoa[event.target.name] = event.target.value;
        setPessoa(newPessoa);
    }

    const handleChangeFile = (event) => {
        let newPessoa = {...pessoa};
        newPessoa.documento = event.target.files[0];
        setPessoa(newPessoa);
    }

    return (
        <form onSubmit={enviarPessoaFisica}>
            <div className="field">
                <div className="control">
                    <input name="nomeFantasia" value={pessoa.nomeFantasia} onChange={handleChange} className={errors.nomeFantasia ? 'input is-danger' : 'input'} type="text" placeholder="Nome Fantasia" />
                </div>
                {errors.nomeFantasia ? <p className="help is-danger">Campo obrigatório</p> : ''}
            </div>

            <div className="field">
                <div className="control">
                    <input name="razaoSocial" value={pessoa.razaoSocial}  onChange={handleChange} className={errors.razaoSocial ? 'input is-danger' : 'input'} type="text" placeholder="Razão Social" />
                </div>
                {errors.razaoSocial ? <p className="help is-danger">Campo obrigatório</p> : ''}
            </div>

            <div className="field">
                <div className="control">
                    <input pattern="https://.*" className={errors.site ? 'input is-danger' : 'input'} name="site" placeholder="Web Site" type="url"  value={pessoa.site}  onChange={handleChange} />
                </div>
                {errors.site ? <p className="help is-danger">Campo obrigatório</p> : ''}
            </div>

            <div className="field">
                <div className="control">
                    <InputMask mask={"99.999.999/9999-99"} className={errors.cnpj ? 'input is-danger' : 'input'} name="cnpj" placeholder="CNPJ"  value={pessoa.cnpj}  onChange={handleChange} inputRef={cpfRef} />
                </div>
                {errors.cnpj ? <p className="help is-danger">Campo obrigatório</p> : ''}
            </div>

            <div className="field">
                <div className="control">
                    <input name="inscricaoEstadual" value={pessoa.inscricaoEstadual} onChange={handleChange} 
                    className={errors.inscricaoEstadual ? 'input is-danger' : 'input'} type="text" placeholder="Inscrição Estadual" />
                </div>
                {errors.inscricaoEstadual ? <p className="help is-danger">Campo obrigatório</p> : ''}
            </div>

            <div className="field">
                <div className="control">
                    <input name="inscricaoMunicipal" value={pessoa.inscricaoMunicipal} onChange={handleChange} 
                    className={errors.inscricaoMunicipal ? 'input is-danger' : 'input'} type="text" placeholder="Inscrição Municipal" />
                </div>
                {errors.inscricaoMunicipal ? <p className="help is-danger">Campo obrigatório</p> : ''}
            </div>

            <div className="field">
                <div className="control">
                    <input name="endereco" value={pessoa.endereco} onChange={handleChange} 
                    className={errors.endereco ? 'input is-danger' : 'input'}
                    type="text" placeholder="Endereço" />
                </div>
                {errors.endereco ? <p className="help is-danger">Campo obrigatório</p> : ''}
            </div>

            <div className="field">
                <div className="control">
                    <InputMask mask={"99999-999"} 
                    className={errors.cep ? 'input is-danger' : 'input'}
                    name="cep" placeholder="CEP"  value={pessoa.cep}  onChange={handleChange} inputRef={cep} />
                </div>
                {errors.cep ? <p className="help is-danger">Campo obrigatório</p> : ''}
            </div>

            <div className="field">
                <div className="control">
                    <InputMask mask={"(99) 9999-9999"} className={errors.telefone ? 'input is-danger' : 'input'} name="telefone" placeholder="Telefone"  value={pessoa.telefone}  onChange={handleChange} inputRef={telefoneComercial} />
                </div>
                {errors.telefone ? <p className="help is-danger">Campo obrigatório</p> : ''}
            </div>

            <div className="field">
                <div className="control">
                    <input name="email" value={pessoa.email} onChange={handleChange} className={errors.email ? 'input is-danger' : 'input'} type="text" placeholder="E-mail" />
                </div>
                {errors.email ? <p className="help is-danger">Campo obrigatório</p> : ''}
            </div>

            <div className="field">
                <div className="control">
                    <input name="cnae" value={pessoa.cnae} onChange={handleChange} 
                    className={errors.cnae ? 'input is-danger' : 'input'} type="text" placeholder="CNAE" />
                </div>
                {errors.cnae ? <p className="help is-danger">Campo obrigatório</p> : ''}
            </div>

            <div className="field">
                <strong>TIPO:</strong>
                <div className="control">
                    <label className="radio">
                        <input type="radio" checked={!pessoa.tipo || pessoa.tipo === 'associação' ? true : false} onChange={handleChange} name="tipo" value="associação" />
                        &nbsp;ASSOCIAÇÃO
                    </label>
                    <label className="radio">
                        <input type="radio" checked={pessoa.tipo === 'empresa' ? true : false} onChange={handleChange} name="tipo" value="empresa" />
                        &nbsp;EMPRESA
                    </label>
                </div>
            </div>

            <div className="field">
                <div className="control">
                    <textarea
                        name="atuacao"
                        value={pessoa.atuacao}
                        onChange={handleChange}
                        className={errors.cnae ? 'textarea is-danger' : 'textarea'}
                        placeholder="Descrição das atividades"></textarea>
                </div>
                {errors.telefone ? <p className="help is-danger">Campo obrigatório</p> : ''}
            </div>

            <div className="field">
                <strong>PORTE:</strong>
                <div className="control">
                    <label className="radio">
                        <input type="radio" checked={!pessoa.porte || pessoa.porte === 'micro' ? true : false} 
                            onChange={handleChange} name="porte"
                            value="micro" />
                            &nbsp;MICRO E PEQUENA(FATURAMENTO ATÉ 16 MILHÕES ANO)
                    </label><br />
                    <label className="radio">
                        <input type="radio" checked={pessoa.porte === 'media' ? true : false} 
                            onChange={handleChange} name="porte"
                            value="media" />
                            &nbsp;MÉDIA (FATURAMENTO DE R$ 16 A 300 MILHÕES ANO)
                    </label><br />
                    <label className="radio">
                        <input type="radio" checked={pessoa.porte === 'grande' ? true : false} 
                            onChange={handleChange} name="porte"
                            value="grande" />
                            &nbsp;GRANDE (FATURAMENTO MAIOR QUE R$ 300 MILHÕES ANO)
                    </label>
                </div>
            </div>

            <div className="field">
                <strong>PLANO DE PAGAMENTO DA CONTRIBUIÇÃO ASSOCIATIVA:</strong>
                <div className="control">
                    <label className="radio">
                        <input type="radio" checked={!pessoa.planoPagamento || pessoa.planoPagamento === 'anual' ? true : false} 
                            onChange={handleChange} name="planoPagamento"
                            value="anual" />
                            &nbsp;Anual
                    </label><br />
                    <label className="radio">
                        <input type="radio" checked={pessoa.planoPagamento === 'mensal' ? true : false} 
                            onChange={handleChange} name="planoPagamento"
                            value="mensal" />
                            &nbsp;Mensal
                    </label>
                </div>
            </div>

            <div className="field">
                <label className="label">DATA DE PAGAMENTO DA CONTRIBUIÇÃO ASSOCIATIVA</label>
                <div className="control">
                    <input className={errors.dataPagamento ? 'input is-danger' : 'input'} name="dataPagamento" placeholder="Data de nascimento" type="date"  value={pessoa.dataPagamento}  onChange={handleChange} />
                </div>
                {errors.dataPagamento ? <p className="help is-danger">Campo obrigatório</p> : ''}
            </div>

            <div className="field">
                <strong>COMO OUVIU FALAR DO ITCN:</strong>
                <div className="control">
                    <label className="radio">
                        <input type="radio" checked={!pessoa.conhecimento || pessoa.conhecimento === 'indicacao' ? true : false} onChange={handleChange} name="conhecimento" value="indicacao" />
                        &nbsp;INDICAÇÃO
                    </label>
                    <label className="radio">
                        <input type="radio" checked={pessoa.conhecimento === 'pesquisa' ? true : false} onChange={handleChange} name="conhecimento" value="pesquisa" />
                        &nbsp;PESQUISA NA WEB
                    </label>
                    <label className="radio">
                        <input type="radio" checked={pessoa.conhecimento === 'evento' ? true : false} onChange={handleChange} name="conhecimento" value="evento" />
                        &nbsp;EVENTO
                    </label>
                    <label className="radio">
                        <input type="radio" checked={pessoa.conhecimento === 'midia' ? true : false} onChange={handleChange} name="conhecimento" value="midia" />
                        &nbsp;MÍDIA
                    </label>
                    <label className="radio">
                        <input type="radio" checked={pessoa.conhecimento === 'outros' ? true : false} onChange={handleChange} name="conhecimento" value="outros" />
                        &nbsp;OUTROS
                    </label>
                </div>
            </div>

            <div className="field">
                <div className="control">
                    <input name="representante" value={pessoa.representante} onChange={handleChange} 
                    className={errors.representante ? 'input is-danger' : 'input'}
                    type="text" placeholder="Representante Legal" />
                </div>
                {errors.representante ? <p className="help is-danger">Campo obrigatório</p> : ''}
            </div>

            <div className="field">
                <div className="control">
                    <input name="representanteEmail" value={pessoa.representanteEmail} onChange={handleChange} 
                    className={errors.representanteEmail ? 'input is-danger' : 'input'}
                    type="text" placeholder="E-mail do representante legal" />
                </div>
                {errors.representanteEmail ? <p className="help is-danger">Campo obrigatório</p> : ''}
            </div>

            <div className="field">
                <div className="control">
                    <InputMask mask={"(99) 9999-9999"}
                    className={errors.representanteTelefone ? 'input is-danger' : 'input'}
                    name="representanteTelefone" placeholder="Telefone do representante legal"
                    value={pessoa.representanteTelefone}  onChange={handleChange} inputRef={telefone} />
                </div>
                {errors.representanteTelefone ? <p className="help is-danger">Campo obrigatório</p> : ''}
            </div>

            <div className="field">
                <strong>REPRESENTANTE INDICADO PARA PARTICIPAR DAS ATIVIDADES DO ITCN</strong>
                <div className="control">
                    <label className="radio">
                        <input type="radio" checked={!pessoa.atividades || pessoa.atividades === 'proprio' ? true : false} 
                            onChange={handleChange} name="atividades"
                            value="proprio" />
                            &nbsp;Será o próprio representante
                    </label><br />
                    <label className="radio">
                        <input type="radio" checked={pessoa.atividades === 'outro' ? true : false} 
                            onChange={handleChange} name="atividades"
                            value="outro" />
                            &nbsp;Outro indicado
                    </label>
                </div>
            </div>

            <div className="field">
                <div className="control">
                    <input name="indicadoNome" value={pessoa.indicadoNome} onChange={handleChange} 
                    className={errors.indicadoNome ? 'input is-danger' : 'input'}
                    type="text" placeholder="Nome do Indicado" />
                </div>
            </div>

            <div className="field">
                <div className="control">
                    <input name="indicadoRG" value={pessoa.indicadoRG} onChange={handleChange} 
                    className={errors.indicadoRG ? 'input is-danger' : 'input'}
                    type="number" placeholder="RG do Indicado" />
                </div>
            </div>

            <div className="field">
                <div className="control">
                    <InputMask mask={"999.999.999-99"} 
                    className={errors.indicadoCPF ? 'input is-danger' : 'input'}
                    name="indicadoCPF" placeholder="CPF do Indicado"  onChange={handleChange} />
                </div>
            </div>

            <div className="field">
                <div className="control">
                    <input name="indicadoEndereco" onChange={handleChange} 
                    className={errors.indicadoEndereco ? 'input is-danger' : 'input'}
                    type="text" placeholder="Endereço do Indicado" />
                </div>
            </div>

            <div className="field">
                <div className="control">
                    <input name="indicadoEmail" onChange={handleChange} 
                    className={errors.indicadoEmail ? 'input is-danger' : 'input'}
                    type="mail" placeholder="Email do Indicado" />
                </div>
            </div>

            <div className="field">
                <div className="control">
                    <InputMask mask={"(99) 9999-9999"} 
                    className={errors.indicadoTelefone ? 'input is-danger' : 'input'}
                    name="indicadoTelefone" placeholder="Telefone do Indicado"  onChange={handleChange} />
                </div>
            </div>

            <div className="file  has-name is-fullwidth">
                <strong>Anexar cartão CNPJ; Contrato Social Atualizado; Cópia de documento oficial com foto do representante indicado. Nos formatos: pdf, doc, jpg.</strong>
                <label className="file-label">
                    <input 
                        className="file-input"
                        accept="image/jpg, image/jpeg, application/pdf, application/doc, application/docx"
                        onChange={handleChangeFile} type="file" name="documento" />
                    <span className="file-cta">
                        <span className="file-icon">
                            <i className="fas fa-upload"></i>
                        </span>
                        <span className="file-label">
                            Escolher arquivo
                        </span>
                    </span>
                    <span className="file-name">
                        { pessoa.documento && pessoa.documento.name ? pessoa.documento.name : '' }
                    </span>
                </label>
                {errors.documento ? <p className="help is-danger">Campo obrigatório</p> : ''}
            </div>


            <div className="field">
                <p>&nbsp;</p>
                <strong>Declaro, que todas as informações acima prestadas são verdadeiras. Declaro ainda ter ciência e concordância com todas as previsões estatutárias do ITCN, comprometendo-me a zelar pelos valores e princípios da instituição, acatar as deliberações da diretoria bem como cumprir todas as normas e regras dispostas no seu estatuto.</strong>
                <div className="control">
                    <label className="radio">
                        <input type="radio" onChange={handleChange} name="declaracao" value="sim" />
                        &nbsp;SIM
                    </label>
                    <label className="radio">
                        <input type="radio" checked={true} onChange={handleChange} name="declaracao" value="nao" />
                        &nbsp;NÃO
                    </label>
                </div>
            </div>

            <div className="field is-grouped">
                <div className="control">
                    <button type="submit" className="button is-success">Enviar Solicitação</button>
                </div>
            </div>

            { msgRetorno ? <article className="message is-info">
                <div className="message-body">
                    { msgRetorno }
                </div>
            </article> : null }
        </form>
    );
};

export default FormPessoaJuridica;