import '../scss/Sidebar.scss';
import React, { useEffect, useState } from 'react';
import { useListarSidebarNoticias } from '../../hooks/useNoticias';
import { Link, useHistory } from 'react-router-dom';


const Sidebar = () => {

    const [lista, setLista] = useState([]);
    const history  = useHistory();
    const {error, data, loading} = useListarSidebarNoticias();

    useEffect(() => {
        if (!loading && !error && data.length > 0) {
            setLista([...data]);
        }
    }, [error, data, loading]);

    const handlePesquisa = () => {
        history.push(`/noticias?q=${document.querySelector('#pesquisa').value}`);
    };

    return (
        <section id="sidebar" className="column is-4">
            { loading ? null : 
                <React.Fragment>
                    <div className="field has-addons">
                        <div className="control">
                            <input id="pesquisa" className="input" type="text" placeholder="Pesquisar" />
                        </div>
                        <div className="control">
                            <button onClick={handlePesquisa} href="#" className="button is-text">
                                <i className="fas fa-search"></i>
                            </button>
                        </div>
                    </div>
                    <h3 className="destaque">Últimas Notícias</h3>
                    <div>
                    {(
                        lista.map(entry => (
                            <div key={entry.id} className="links">
                                <Link to={`/noticias/${entry.slug}`}>
                                    {entry.titulo}
                                </Link>
                            </div>
                        ))
                    )}
                    </div>
                    <h3 className="destaque">Categorias</h3>
                    <div>
                        <div className="links">
                            <Link to={`/noticias`}>
                                Notícias
                            </Link>
                        </div>
                        <div className="links">
                            <Link to={`/agenda`}>
                                Agenda
                            </Link>
                        </div>
                    </div>
                </React.Fragment>
            }
        </section>
    );
}

export default Sidebar;