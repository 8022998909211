import React, { useState, useEffect } from 'react';
import htmlParser from '../../utils/htmlParser';
import useSWR from 'swr';
import Loading from '../loading/Loading';
import ListaDestaqueItem from './ListaDestaqueItem';
import './ListaDestaqueStyle.css';
import api from '../../config/api';

const documentosMock = { slug: 'notícias', titulo: 'Notícias', resumo: 'Lista de notícias do ITCN' };
const fetcher = async url =>  await fetch(url).then(r => r.json())

const ListaDestaque = (props) => {

    const {limit, title} = props;
    const [listaNoticia, setListaNoticia] = useState([]);
    const [listDocumento, setlistDocumento] = useState([]);
    const [waitScreen, setWaitScreen] = useState(true);
    const listNoticias = useSWR(`${api}/noticias?_sort=createdAt%3Adesc&_limit=${limit}&realcar=true`, fetcher);
    const listDocumentos = useSWR(`${api}/documentos-publicacoes?_sort=createdAt%3Adesc&_limit=${limit}&realcar=true`, fetcher);

    useEffect(() => {
        
        if (listNoticias.loading) {
            setWaitScreen(true);
        } else {
            if (listNoticias.error) {
                setListaNoticia([{ ...documentosMock, conteudo: "<h1>Ocorreu um erro, por favor, tente novamente</h1>" }]);
            } else if (listNoticias.data) {
                
                if (listNoticias.data.length > 0){
                    setListaNoticia([...listNoticias.data]);
                }
            }
            
            setWaitScreen(false);
        }

    }, [listNoticias.loading, listNoticias.error, listNoticias.data]);

    useEffect(() => {
        
        if (listDocumentos.loading) {
            setWaitScreen(true);
        } else {
            if (listDocumentos.error) {
                setlistDocumento([{ ...documentosMock, conteudo: "<h1>Ocorreu um erro, por favor, tente novamente</h1>" }]);
            } else if (listDocumentos.data) {
                if (listDocumentos.data.length > 0){
                    setlistDocumento([...listDocumentos.data]);
                }
            }

            setWaitScreen(false);
        }

    }, [listDocumentos.loading, listDocumentos.error, listDocumentos.data]);

    return (
        <div className="lista-destaque">
            <div className="title-container">
                <h3 className="titulo">{ title !== undefined ? title : '' }</h3>
            </div>
            { waitScreen ? (<Loading />) : 
                <div className="container">
                    <section id="conteudo" className="columns is-multiline">
                        { listaNoticia && listaNoticia.length > 0 ?
                            listaNoticia.map(entry => {
                                if (!entry.hasOwnProperty('_id')) {
                                    return htmlParser(entry.conteudo);
                                }
                                return <ListaDestaqueItem key={entry.id} post={entry} path="/noticias/" columns="4" />;
                            })
                        : null }   

                        { listDocumento && listDocumento.length > 0 ?
                            listDocumento.map(entry => {
                                if (!entry.hasOwnProperty('_id')) {
                                    return htmlParser(entry.conteudo);
                                }
                                return <ListaDestaqueItem key={entry.id} post={entry} path="/documentos/" columns="4" />;
                            })
                        : null }   
                    </section>
                </div>
            }
        </div>
    );
}

export default ListaDestaque;