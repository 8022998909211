import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./interface/Header";
import Footer from "./interface/Footer";

const Shell = props => {
    const { children } = props;

    return (
      <Router>
        <Header />
        {children}
        <Footer />
      </Router>
    );
  };

  export default Shell;