import { useLayoutEffect, useState } from 'react';

export default () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateScroll() {
      setSize([window.scrollX, window.scrollY]);
    }
    window.addEventListener('scroll', updateScroll);
    updateScroll();
  }, []);
  return size;
}