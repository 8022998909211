import React from "react";
import { Switch, Route } from "react-router-dom";
import Reveal from "react-reveal/Reveal";
import HomeComponent from "../components/home/HomeComponent";
import PaginaComponent from "../components/pagina/PaginaComponent";
import NoticiaComponent from "../components/noticia/NoticiaComponent";
import AgendaComponent from "../components/agenda/AgendaComponent";
import DocumentoComponent from "../components/documento/DocumentoComponent";
import VideoComponent from "../components/video/VideoComponent";

const TransitionHOF = WrappedComponent =>  props => {
  return (<Reveal>
    <WrappedComponent {...props} />
  </Reveal>)
};

const AnimatedHome = TransitionHOF(HomeComponent);
const AnimatedPagina = TransitionHOF(PaginaComponent);
const AnimatedNoticia = TransitionHOF(NoticiaComponent);
const AnimatedAgenda = TransitionHOF(AgendaComponent);
const AnimatedDocumento = TransitionHOF(DocumentoComponent);
const AnimatedVideo = TransitionHOF(VideoComponent);

export default props => {
  return (
    <Switch>
      <Route exact path="/" component={AnimatedHome} />
      <Route exact path="/noticias/:slug" children={<AnimatedNoticia />} />
      <Route exact path="/noticias" children={<AnimatedNoticia />} />
      <Route exact path="/agenda/:slug" children={<AnimatedAgenda />} />
      <Route exact path="/agenda" children={<AnimatedAgenda />} />
      <Route exact path="/documentos/:slug" children={<AnimatedDocumento />} />
      <Route exact path="/documentos" children={<AnimatedDocumento />} />
      <Route exact path="/videos" children={<AnimatedVideo />} />
      <Route path="/:slug" component={AnimatedPagina} />
    </Switch>
  );
};
