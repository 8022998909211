import React from 'react';
import Modal from './ModalComponent';
import api from '../../config/api';

const ListaItem = (props) => {
    const { post, columns, inClass } = props;
    return (
        <div className={`column is-${ columns !== undefined ? columns : '12' }`}>

            <div className={`article ${inClass !== undefined ? inClass : '' } `}>
                <div className="card">
                    <div className="card-image">
                        <Modal 
                        isActive={ false } 
                        video={ post.id_video } 
                        image={ post.destaque ? api + post.destaque.url : '' } 
                        title={ post.title }
                        description={ post.conteudo }
                        />
                    </div>

                    <div className="card-content">
                        <div className="media">
                            <div className="media-content">
                                <p className="title is-4">
                                        { post.title }
                                </p>
                                <p className="subtitle is-6"><i className="far fa-clock"></i> { (new Date(post.data  + ' 23:59:59')).toLocaleDateString() }</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ListaItem;