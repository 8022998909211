import React from 'react';
import { useParams } from 'react-router-dom';
import './AgendaStyle.css';
import Lista from './Lista';
import Single from './Single';


const AgendaComponent = () => {

    const { slug } = useParams();

    return (
        <React.Fragment>
            { slug ? (<Single slug={slug} />) : (<Lista />) }
        </React.Fragment>
    );
}

export default AgendaComponent;