import useSWR from 'swr';
import API from '../../app/config/api'

const fetcher = async url => await fetch(url).then(r => r.json())

const useFindNoticia = (page) => {
  const fetched = useSWR(`${API}/noticias?slug=${page}`, fetcher)
  fetched.loading = fetched.data === undefined;
  return fetched
}

const useListarNoticias = (hasFiltro, filtro) => {
  let qs = '';

  if (hasFiltro && filtro !== '') {
    qs = `&_q=${encodeURI(filtro)}`;
  }

  const fetched = useSWR(`${API}/noticias?_sort=createdAt%3Adesc${qs}`, fetcher)

  fetched.loading = fetched.data === undefined;
  return fetched
}

const useHomeNoticias = () => {
  const fetched = useSWR(`${API}/noticias?_sort=createdAt%3Adesc&_limit=6`, fetcher)

  fetched.loading = fetched.data === undefined;
  return fetched
}

const useListarSidebarNoticias = () => {
  const fetched = useSWR(`${API}/noticias?_sort=createdAt%3Adesc&_limit=5`, fetcher)
  fetched.loading = fetched.data === undefined;
  return fetched
}

export {
  useFindNoticia,
  useListarNoticias,
  useListarSidebarNoticias,
  useHomeNoticias
};