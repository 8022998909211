import React from 'react';
import htmlParser from '../../utils/htmlParser';
import { Link } from 'react-router-dom';
import api from '../../config/api';

const ListaItem = (props) => {
    const { post } = props;
    return (
        <div className="article">
            <div className="titulo">
                <h2>
                    <Link to={`/agenda/${post.slug}`}>
                        { post.titulo }
                    </Link>
                </h2>
            </div>
            <div className="metadata">
                <i className="far fa-clock"></i> { (new Date(post.createdAt)).toLocaleDateString() }
            </div>
            { post.destaque ? 
            <div className="destaque">
                <figure>
                    <img src={`${api}${post.destaque.url}`} alt={post.destaque.alt} />
                </figure>
            </div> : null
            }
            <div className="conteudo">
                { htmlParser(post.resumo ? post.resumo : post.conteudo) }
            </div>
        </div>
    );
}

export default ListaItem;