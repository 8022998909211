import React from "react";
import ReactHtmlParser from 'react-html-parser';
import Loading from '../components/loading/Loading';
import BotaoDestaque from "../template/interface/BotaoDestaque";
import LinkDestaque from "../template/interface/LinkDestaque";
import FormPessoaFisica from "../template/interface/FormPessoaFisica";
import FormPessoaJuridica from "../template/interface/FormPessoaJuridica";
import NoticiasDestaque from "../template/interface/NoticiasDestaque";
import AgendaDestaque from "../template/interface/AgendaDestaque";

export default (conteudo) => {
   return ReactHtmlParser(conteudo, {
        transform: (node) => {
            if (node.name === 'loading') {
                return <Loading key="loading" />;
            }

            if (node.name === 'botaodestaque') {
                return <BotaoDestaque key={node.attribs.texto} to={node.attribs.to} texto={node.attribs.texto} />;
            }

            if (node.name === 'linkdestaque') {
                return <LinkDestaque
                    key={node.attribs.textoprimario}
                    to={node.attribs.to}
                    textoPrimario={node.attribs.textoprimario}
                    textoSecundario={node.attribs.textosecundario}
                    textoBotao={node.attribs.textobotao}
                    background={node.attribs.background}
                    />;
            }

            if (node.name === 'formpessoafisica') {
                return <FormPessoaFisica key="pf" />
            }

            if (node.name === 'formpessoajuridica') {
                return <FormPessoaJuridica key="pj" />
            }

            if (node.name === 'noticiasdestaque') {
                return <NoticiasDestaque key="nd" />
            }

            if (node.name === 'agendadestaque') {
                return <AgendaDestaque key="ad" />
            }
        }
    })
}