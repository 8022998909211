import React, { useState, useEffect } from 'react';
import htmlParser from '../../utils/htmlParser';
import { useListarAgenda } from '../../hooks/useAgenda';
import Loading from '../loading/Loading';
import ListaItem from './ListaItem';
import Sidebar from '../../template/interface/Sidebar';

const noticiasMock = { slug: 'agendas', titulo: 'Agenda', resumo: 'Lista de eventos/agenda do ITCN' };

const Lista = (props) => {

    const [lista, setLista] = useState([]);
    const [waitScreen, setWaitScreen] = useState(true);
    const {error, data, loading} = useListarAgenda();

    useEffect(() => {
        document.title = `ITCN - ${noticiasMock.titulo}`;
        document.head.querySelector('meta[name=description]').content = noticiasMock.resumo;
    }, [lista])

    useEffect(() => {
        if (loading) {
            setWaitScreen(true);
        } else {
            if (error) {
                setLista([{ ...noticiasMock, conteudo: "<h1>Ocorreu um erro, por favor, tente novamente</h1>" }]);
            } else if (data.length === 0) {
                setLista([{ ...noticiasMock, conteudo: "<p>Não temos agenda no momento</p>" }]);
            } else {
                if (data.statusCode && data.statusCode > 200) {
                    setLista([{ ...noticiasMock, conteudo: "<h1>Ocorreu um erro, por favor, tente novamente</h1>" }]);
                } else {
                    setLista([...data]);
                }
            }
            setWaitScreen(false);
        }
    }, [error, data, loading]);

    return (
        <div>
            <div className="banner-notice">
                <h2 className="page-title">Atividades / Agenda</h2>
            </div>
            <main className="noticias page">
                { waitScreen ? (<Loading />) : 
                <div className="container">
                    <div className="columns is-5 is-variable">
                        <section id="conteudo" className="column is-8">
                            { lista && lista.length > 0 ?
                                lista.map(entry => {
                                    if (!entry.hasOwnProperty('_id')) {
                                        return htmlParser(entry.conteudo);
                                    }
                                    return <ListaItem key={entry.id} post={entry} />;
                                })
                            : null }
                        </section>
                        <Sidebar />
                    </div>
                </div>
                }
            </main>
        </div>
    );
}

export default Lista;