import '../scss/botaoDestaque.scss';
import React from 'react';
import { Link } from 'react-router-dom';


const BotaoDestaque = (props) => {
    return (
        <Link className="botao-destaque" to={props.to}>
            {props.texto}
        </Link>
    );
}

export default BotaoDestaque;